import { actions as apiClientActions } from '@spq/redux-api-client';
import { toCamelCaseKeys } from '@spq/utils';
import { chain } from 'lodash';
import moment from 'moment';
import { push, replace } from 'redux-first-history';
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import { withToken } from '../actions/api';
import * as gglocationsActions from '../actions/gglocations';
import * as loggingActions from '../actions/logging';
import * as orderActions from '../actions/order';
import * as orderItemActions from '../actions/orderItem';
import * as pageActions from '../actions/page';
import * as userActions from '../actions/user';
import diningChoiceEnum from '../enums/diningChoiceEnum';
import gglocationTypeEnum from '../enums/gglocationTypeEnum';
import orderEnum from '../enums/orderEnum';
import orderTypeEnum from '../enums/orderTypeEnum';
import statusEnum from '../enums/statusEnum';
import { dispatchCallResult } from '../utils/apiUtils';
import * as gglocationUtils from '../utils/gglocationUtils';
import { deleteSearchParam, getSearchParam, setSearchParams } from '../utils/historyUtils';
import * as orderItemUtils from '../utils/orderItemUtils';
import { parseReplaceOrderPreviewResponse } from '../utils/orderPreviewUtils';
import * as orderUtils from '../utils/orderUtils';

import { VERSION as version } from '../version';
import * as selectors from './selectors';
import { clearSignInHistory } from './userSaga';

import ecomService, { paymentService } from '../services/api';
import { ORDER_DELAY_TIME } from '../settings';

function* hasUserMetPlaceOrderRequirement() {
  const user = yield select(selectors.getUser);
  const selectedPaymentMethod = yield select(selectors.getSelectedPaymentMethod);

  return (
    user.signedIn &&
    user.phoneConfirmed &&
    !!selectedPaymentMethod &&
    selectedPaymentMethod.id !== 'add'
  );
}

function* redirectUserPlaceOrderRequirementFlow() {
  const user = yield select(selectors.getUser);
  const selectedPaymentMethod = yield select(selectors.getSelectedPaymentMethod);

  if (user.signedIn === false) {
    yield put(push({ pathname: '/signIn' }));
  } else if (user.phoneConfirmed === false) {
    yield call(clearSignInHistory);
    yield put(push({ pathname: '/signIn/phone' }));
  } else if (!selectedPaymentMethod) {
    const defaultPaymentMethod = yield select(selectors.getUserDefaultPaymentMethod);

    // If user has a defaultPaymentMethod, use that
    if (defaultPaymentMethod) {
      yield put(orderActions.selectPaymentMethod(defaultPaymentMethod));
      yield put(userActions.signedIn());
    } else {
      // If not, redirect to add payment method page
      yield put(push({ pathname: '/signIn/payment' }));
    }
  } else if (selectedPaymentMethod.id === 'add') {
    // Redirect to add payment method page to add new payment method
    yield put(push({ pathname: '/signIn/payment' }));
  }
}

function* placeOrderDispatch() {
  const canPlaceOrder = yield hasUserMetPlaceOrderRequirement();

  if (canPlaceOrder) {
    yield put(orderActions.delayOrder());
  } else {
    const { pathname, search } = yield select(selectors.getCurrentLocation);

    yield put(
      userActions.beforeSignIn({
        pathname,
        search: setSearchParams(search, { showCart: true }),
      }),
    );
    yield put(orderActions.saveBeforeSignInOrderPrice());
    yield put(
      userActions.afterSignIn({
        pathname,
        search: setSearchParams(search, { showCart: false, placeOrder: true }),
      }),
    );
    yield call(redirectUserPlaceOrderRequirementFlow);
  }
}

function* placeOrder() {
  const order = yield select(selectors.getOrder);
  const gglocations = yield select(selectors.getGglocations);
  const gglocation = gglocations.gglocations[order.gglocationId];
  const timeSlot = gglocations.timeSlots[order.timeSlotId];
  const paymentMethod = yield select(selectors.getSelectedPaymentMethod);
  const { diningChoice, customerAddressId } = order;

  const price = yield select(selectors.getOrderTotalPrice);

  const userRewardUuid = yield select(selectors.getSelectedUserRewardUuid);
  const promoCode = yield select(selectors.getSelectedPromoCode);
  const body = {
    order,
    gglocation,
    timeSlot,
    userRewardUuid,
    promoCode,
    paymentMethod,
    price,
    diningChoice,
    customerAddressId,
    version,
  };
  const result = yield ecomService.placeOrder.call(body);

  if (result.error) {
    yield dispatchCallResult({ requestActionType: orderActions.PLACE_ORDER, result });
  } else {
    let { response } = result;

    if (paymentMethod.methodType === 'CARD') {
      const uuid = paymentMethod.id;
      yield put(paymentService.setDefaultPaymentMethod.requestActionCreator({ uuid }));
    }

    /*
      Ecom backend might return early so we should check Pusher updates to
      find out when the order will be created.
    */
    while (
      response.orderStatusId >= orderEnum.CREATED &&
      response.orderStatusId < orderEnum.PROCEEDING_TO_STORE &&
      !orderUtils.isOrderOnHold(response.orderStatusId) &&
      !orderUtils.isOrderStatusFailed(response.orderStatusId)
    ) {
      const updateOrderAction = yield take(userActions.UPDATE_ORDER);
      const updatedResponse = toCamelCaseKeys(updateOrderAction.response);
      response = { ...response, ...updatedResponse };
    }

    yield put(
      apiClientActions.apiCallResult({
        apiName: ecomService.apiName,
        apiReference: ecomService.apiReference,
        endpointName: `${ecomService.placeOrder}`,
        apiSettings: ecomService.apiSettings,
        result: { response },
      }),
    );

    yield dispatchCallResult({
      requestActionType: orderActions.PLACE_ORDER,
      result: { response },
    });
  }
}

function* handlePlaceOrderApplePay(action) {
  const session = action.applePaySession;
  let order = yield select(selectors.getOrder);

  while (order.orderStatus >= 0) {
    order = yield select(selectors.getOrder);
    if (order.orderStatus === statusEnum.SUCCESS) {
      session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
      break;
    } else if (order.orderStatus === statusEnum.ERROR) {
      session.completePayment(window.ApplePaySession.STATUS_FAILURE);
      break;
    }
    yield delay(50);
  }
}

function* createOrderItemFromUserOrderItem(userOrderItem) {
  /* If the orderItem has a custom menusnapshot, use that */
  if (userOrderItem.customMenuSnapshot) {
    try {
      yield put(
        orderItemActions.createOrderItemFromUserOrderItemId({
          userOrderItemId: userOrderItem.customMenuSnapshot,
          personalSettings: userOrderItem.personalSettings,
        }),
      );
    } catch (e) {
      yield put(orderItemActions.createOrderItemError({ error: e }));
    }
  } else {
    /* If not, look for menuItem in the gglocation */
    const menuItem = yield select(selectors.findGglocationMenuItemByApiId, userOrderItem.apiId);
    /* If the item does not exist, add it to unavailable items */
    if (!menuItem) {
      yield put(orderActions.addUnavailableItems([{ apiId: userOrderItem.apiId }]));
    } else {
      try {
        yield put(
          orderItemActions.createOrderItemFromMenuItemId({
            menuItemId: menuItem.id,
            personalSettings: userOrderItem.personalSettings,
          }),
        );
      } catch (e) {
        yield put(orderItemActions.createOrderItemError({ error: e }));
      }
    }
  }
}

function* replaceOrderDispatch(action) {
  const userOrders = yield select(selectors.getUserOrders);
  const userOrder = userOrders[action.userOrderId];

  const result = yield ecomService.replaceOrderPreview.call({ orderId: action.userOrderId });
  const parsedResult = parseReplaceOrderPreviewResponse({ result });

  yield dispatchCallResult({
    requestActionType: orderActions.REPLACE_ORDER,
    result: parsedResult,
  });

  if (parsedResult.response) {
    // eslint-disable-next-line no-restricted-syntax
    for (const userOrderItem of userOrder.ordermenu) {
      yield createOrderItemFromUserOrderItem(userOrderItem);
      /* Wait until the order item is created */
      yield take(orderItemActions.CREATE_ORDER_ITEM_SUCCESS);
      const orderItem = yield select(selectors.getOrderItem);

      if (orderItemUtils.isOrderItemValid(orderItem)) {
        yield put(orderItemActions.saveOrderItem());
        yield take(orderActions.UPDATE_ORDER_ORDER_ITEM);
      } else {
        /* Wait until the issue with the order has been resolved */
        const resolution = yield take([
          orderActions.RESET_ORDER,
          orderItemActions.RESET_ORDER_ITEM,
        ]);

        /* Break the loop if user decided to reset order, otherwise continue adding menu items. */
        if (resolution.type === orderActions.RESET_ORDER) {
          break;
        }
      }
    }

    const order = yield select(selectors.getOrder);

    if (
      order.orderItemsIds.length &&
      (order.diningChoice !== diningChoiceEnum.DELIVERY || !!order.deliveryAddress)
    ) {
      yield put(push({ pathname: '/' }));
      yield put(orderActions.showCart());
    }
  }

  if (result.error.data?.error === 'ERROR_MENU_INVALID') {
    // remove re-place order button
    yield put(orderActions.addReplaceOrderErrorOrderId({ userOrderId: action.userOrderId }));
  }
}

function* delayOrder() {
  const { countdown } = yield race({
    countdown: delay(ORDER_DELAY_TIME),
    cancel: take(orderActions.ROLLBACK_AWAITING_ORDER),
  });
  if (countdown) {
    yield put(withToken(orderActions.placeOrder()));
  }
}

function* handleOrderPlaced(action) {
  const { response } = action;
  const { search } = yield select(selectors.getCurrentLocation);

  yield put(loggingActions.logCartConfirm({ order: response }));
  yield put(
    push({
      pathname: `/order/${response.uuid}`,
      search: setSearchParams(search, { redirected: true, showCart: false }),
    }),
  );
}

function* handleOrderPaymentFailure() {
  const paymentMethod = yield select(selectors.getSelectedPaymentMethod);

  const currentLocation = yield select(selectors.getCurrentLocation);
  yield put(
    userActions.beforeSignIn({
      pathname: currentLocation.pathname,
      search: currentLocation.search,
    }),
  );
  yield put(
    userActions.afterSignIn({
      pathname: currentLocation.pathname,
      search: setSearchParams(currentLocation.search, { placeOrder: true }),
    }),
  );

  yield put(
    replace({
      pathname: '/signIn/payment/failure',
      search: setSearchParams(currentLocation.search, { paymentMethodId: paymentMethod.id }),
    }),
  );
}

function* handleOrderError(action) {
  const { error } = action;

  if (error.code === orderEnum.FAILED_PAYMENT_AUTH) {
    const paymentMethod = yield select(selectors.getSelectedPaymentMethod);
    if (paymentMethod.methodType === 'APPLEPAY') {
      yield put(orderActions.showCart());
    } else {
      yield handleOrderPaymentFailure();
    }
  } else {
    yield put(orderActions.showCart());
  }
}

function* handleSignInProcessCompleted() {
  const { pathname, search } = yield select(selectors.getAfterSignIn);

  if (search && getSearchParam(search, 'placeOrder')) {
    const canPlaceOrder = yield call(hasUserMetPlaceOrderRequirement);
    if (canPlaceOrder) {
      yield call(clearSignInHistory);
      yield put(replace({ pathname, search: deleteSearchParam(search, 'placeOrder') }));
      yield put(userActions.afterSignIn({ pathname: '/locations' }));
      yield put(orderActions.awaitOrderPrice());
    } else {
      yield call(redirectUserPlaceOrderRequirementFlow);
    }
  }
}

function* handleAwaitOrderPrice() {
  const { ready: beforeSignInOrderReadyPrice } = yield select(selectors.getBeforeSignInOrderPrice);

  yield put(orderActions.previewOrderDispatch());

  const { updateReadyPriceAction } = yield race({
    updateReadyPriceAction: take(orderActions.UPDATE_PRICES),
    error: take(orderActions.ORDER_PREVIEW_ERROR),
  });

  if (
    updateReadyPriceAction &&
    updateReadyPriceAction.totalPrice === beforeSignInOrderReadyPrice.totalPrice
  ) {
    yield put(orderActions.placeOrderDispatch());
  } else {
    yield put(orderActions.rollbackAwaitingOrder());
    yield put(orderActions.showCart());
  }
}

function* resetGglocation() {
  const diningChoice = yield select(selectors.getDiningChoice);
  const isStationFlow = yield select(selectors.getIsStationFlow);

  if (diningChoice === diningChoiceEnum.DELIVERY) {
    yield put(orderActions.updateGglocation({ gglocation: null }));
  } else {
    const isLandingPageOrder = yield select(selectors.getIsLandingPageOrder);
    const landingPageDisabledGglocationTypes = yield select(
      selectors.getLandingPageDisabledGglocationTypes,
    );
    const landingPagePartners = yield select(selectors.getLandingPagePartners);
    // During this point of time, we do not know if the landing is only allow station flow only
    // so we need to check the the landing page disable store but enable partner
    const isLandingPageOrStationFlow =
      isStationFlow ||
      (isLandingPageOrder &&
        landingPageDisabledGglocationTypes.includes(gglocationTypeEnum.STORE) &&
        !landingPageDisabledGglocationTypes.includes(gglocationTypeEnum.PARTNER));
    const excludedGglocationTypes = [
      isLandingPageOrStationFlow ? gglocationTypeEnum.STORE : gglocationTypeEnum.PARTNER,
    ];
    const options = yield select((state) => ({
      isUsedSignedIn: state.user.signedIn,
      api: {
        gglocations: state.api.gglocations,
        timeSlots: state.api.timeSlots,
        mostFrequentGglocationIds: state.api.mostFrequentGglocationIds,
        mostRecentGglocationIds: state.api.mostRecentGglocationIds,
      },
      landingPagePartners,
      isLandingPageOrder,
      landingPageDisabledGglocationTypes,
      geolocation: state.geolocation,
      diningChoice,
      excludedGglocationTypes,
    }));
    const gglocationIds = gglocationUtils.getRecommendedGglocationIds(options);
    const gglocation = yield select(selectors.selectGglocation, gglocationIds[0]);
    const timeSlots = yield select(selectors.getTimeSlots);

    yield put(orderActions.updateGglocation({ gglocation, timeSlots }));
  }
}

function* handleSetDiningChoice() {
  yield put(orderActions.resetGglocation());
}

function* modifyOrderItem({ orderItemId }) {
  const orderItem = yield select(selectors.selectOrderOrderItem, orderItemId);

  yield put(orderActions.hideCart());
  yield put(orderItemActions.modifyOrderItem({ orderItem }));
  yield put(pageActions.openMenuItemPage());
}

function* handleRemoveOrderItemDispatch(action) {
  yield put(loggingActions.logRemoveItemFromCart({ orderItemId: action.orderItemId }));
  yield put(orderActions.removeOrderItem(action.orderItemId));
}

function* handleCheckUnavailableItems() {
  const orderItems = yield select(selectors.getOrderOrderItems);
  const menuGroupId = yield select(selectors.getCurrentMenuGroupId);

  if (menuGroupId) {
    const menuGroup = yield select(selectors.selectMenuGroup, menuGroupId);

    const unavailableOrderItems = chain(orderItems)
      .filter((orderItem) => !menuGroup.includes(`${orderItem.apiId}_${menuGroupId}`))
      .map((orderItem) => ({ apiId: orderItem.apiId }))
      .uniq()
      .value();
    yield put(orderActions.addUnavailableItems(unavailableOrderItems));
  }
}

function* handleUpdateGglocation() {
  const gglocation = yield select(selectors.getOrderGglocation);
  const diningChoice = yield select(selectors.getDiningChoice);
  const isLandingPageOrder = yield select(selectors.getIsLandingPageOrder);

  if (!gglocation && isLandingPageOrder && diningChoice === diningChoiceEnum.TAKE_AWAY) {
    yield put(push({ pathname: '/locations' }));
  }
  yield handleCheckUnavailableItems();
}

function* handleHighlightGglocation(action) {
  const gglocation = yield select(selectors.selectGglocation, action.id);
  const timeSlots = yield select(selectors.getTimeSlots);

  yield put(orderActions.updateGglocation({ gglocation, timeSlots }));
}

function* handleLocationChange(action) {
  const openMenuItemPageAfterTimeSelected = yield select(
    selectors.getOpenMenuItemPageAfterTimeSelected,
  );
  const openCartAfterTimeSelected = yield select(selectors.getOpenCartAfterTimeSelected);

  if (openMenuItemPageAfterTimeSelected && action.payload.pathname === '/menu') {
    yield put(pageActions.openMenuItemPage());
  }

  if (openCartAfterTimeSelected && action.payload.pathname === '/menu') {
    yield put(orderItemActions.saveOrderItem());
    yield put(orderActions.showCart());
  }
}

function* ensureInstantGglocationOrderTimePastCutoff(order, gglocation) {
  const nextAvailableDate = gglocation.getNextAvailableScheduledDate({
    diningChoice: order.diningChoice,
  });

  if (nextAvailableDate) {
    if (moment(order.orderTime).isBefore(nextAvailableDate)) {
      yield put(orderActions.setOrderTime(nextAvailableDate));
    }
  } else {
    /* TODO: Notify user if store has closed down. */
  }
}

function* ensureScheduledGglocationOrderTimePastCutoff(order, gglocation) {
  const { diningChoice } = order;
  const timeSlots = yield select(selectors.getTimeSlots);
  const selectedTimeSlot = Object.values(timeSlots).find(
    (timeSlot) => timeSlot.id === order.timeSlotId,
  );
  const nextAvailableTimeSlot = gglocation.getNextTimeSlot({ diningChoice });

  if (nextAvailableTimeSlot) {
    if (moment(selectedTimeSlot.datetime).isBefore(nextAvailableTimeSlot.datetime)) {
      yield put(orderActions.updateTimeSlot(nextAvailableTimeSlot.id));
    }
  } else {
    /* TODO: Notify user if store has closed down. */
  }
}

/* Makes sure order time is always past the cut off time */
function* ensureOrderTimePastCutoff() {
  const order = yield select(selectors.getOrder);
  const gglocations = yield select(selectors.getGglocations);
  const gglocation = gglocations.gglocations[order.gglocationId];

  if (order.orderType === orderTypeEnum.SCHEDULED) {
    if (gglocation) {
      if (gglocation.type === orderTypeEnum.INSTANT) {
        yield ensureInstantGglocationOrderTimePastCutoff(order, gglocation);
      } else {
        yield ensureScheduledGglocationOrderTimePastCutoff(order, gglocation);
      }
    }
  }

  /* The worker needs to be timed so that it runs on the first moment of the next minute. */
  const now = moment();
  const millisecondsIntoThisMinute = now.seconds() * 1000 + now.milliseconds();
  const millisecondsUntilNextMinute = 60 * 1000 - millisecondsIntoThisMinute;

  yield delay(millisecondsUntilNextMinute);
  yield ensureOrderTimePastCutoff();
}

function* updateScheduler() {
  const order = yield select(selectors.getOrder);
  const selectedPaymentMethod = yield select(selectors.getSelectedPaymentMethod);
  const { diningChoice, customerAddressId } = order;

  const body = {
    order,
    paymentMethod: selectedPaymentMethod,
    diningChoice,
    customerAddressId,
    version,
  };
  const { response, error } = yield ecomService.previewOrder.call(body);

  if (error) {
    yield put(orderActions.setOrderType(orderTypeEnum.SCHEDULED));
    yield put(
      orderActions.updateScheduler({
        gglocationId: null,
        processingTime: null,
        cutoffTime: null,
      }),
    );
  }

  if (response) {
    const gglocationId = `${response.gglocationType}_${response.gglocationId}`;
    const gglocation = yield select(selectors.selectGglocation, gglocationId);
    const storeBusy = yield select(selectors.getGglocationStoreBusy, gglocation?.gglocationId);

    const processingTime = gglocation?.getProcessingTime({ diningChoice, storeBusy });
    const cutoffTime = gglocation?.getCutoffTime({ diningChoice });

    yield put(
      orderActions.updateScheduler({
        gglocationId,
        processingTime,
        cutoffTime,
      }),
    );
  }
}

function* createUpsellMenuItems(action) {
  // eslint-disable-next-line no-restricted-syntax
  for (const menuItemId of action.menuItemIds) {
    yield put(orderItemActions.createOrderItemFromMenuItemId({ menuItemId, isUpsold: true }));
    /* Wait until the order item is created */
    yield take(orderItemActions.CREATE_ORDER_ITEM_SUCCESS);
    const orderItem = yield select(selectors.getOrderItem);

    if (orderItemUtils.isOrderItemValid(orderItem)) {
      yield put(orderItemActions.saveOrderItem());
      yield take(orderActions.UPDATE_ORDER_ORDER_ITEM);
    }
  }
}

function* watchOrder() {
  yield takeEvery(orderActions.PLACE_ORDER_DISPATCH, placeOrderDispatch);
  yield takeEvery(orderActions.PLACE_ORDER_REQUESTED, placeOrder);
  yield takeLatest(orderActions.REPLACE_ORDER_DISPATCH, replaceOrderDispatch);
  yield takeEvery(orderActions.PLACE_ORDER_SUCCESS, handleOrderPlaced);
  yield takeEvery(orderActions.PLACE_ORDER_ERROR, handleOrderError);
  yield takeEvery(orderActions.UPDATE_GGLOCATION, handleUpdateGglocation);
  yield takeEvery(gglocationsActions.HIGHLIGHT_GGLOCATION, handleHighlightGglocation);
  yield takeLatest(orderActions.DELAY_ORDER, delayOrder);
  yield takeLatest(orderActions.AWAIT_ORDER_PRICE, handleAwaitOrderPrice);
  yield takeEvery(userActions.SIGNED_IN, handleSignInProcessCompleted);
  yield takeEvery(orderActions.CREATE_UPSELL_MENU_ITEMS, createUpsellMenuItems);

  yield takeEvery(orderActions.SET_DINING_CHOICE, handleSetDiningChoice);
  yield takeEvery([orderActions.RESET_GGLOCATION, orderActions.SELECT_ADDRESS], resetGglocation);
  yield takeEvery(orderActions.SELECT_ADDRESS, updateScheduler);
  yield takeEvery(orderActions.SET_LANDING_PAGE, handleCheckUnavailableItems);

  yield takeEvery(orderActions.HANDLE_PLACE_ORDER_APPLE_PAY, handlePlaceOrderApplePay);
}

function* watchOrderItem() {
  yield takeEvery(orderActions.MODIFY_ORDER_ORDER_ITEM, modifyOrderItem);
  yield takeEvery(orderActions.REMOVE_ORDER_ITEM_DISPATCH, handleRemoveOrderItemDispatch);
}

function* watchLocation() {
  yield takeEvery('@@router/LOCATION_CHANGE', handleLocationChange);
}

export default function* orderSaga() {
  yield all([ensureOrderTimePastCutoff(), watchOrder(), watchOrderItem(), watchLocation()]);
}
