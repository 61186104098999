import accountStatusEnum from '../enums/accountStatusEnum';

export const prepareRewardsResponse = ({ data }) => {
  const rewardUuids = [];
  const rewards = {};

  [...data.response.userReward, ...data.response.targetingUserReward].map((reward) => {
    rewardUuids.push(reward.uuid);
    rewards[reward.uuid] = reward;
    return reward;
  });

  return {
    response: {
      rewardUuids,
      rewards,
    },
  };
};

export const preparePaymentMethodResponse = ({ data }) => {
  const paymentMethods = {};
  const paymentMethodIds = [];
  let defaultPaymentMethod = null;
  let applePayPaymentMethod = null;

  data.response.map((paymentMethod) => {
    paymentMethods[paymentMethod.id] = paymentMethod;
    paymentMethodIds.push(paymentMethod.id);
    if (paymentMethod.isDefault) {
      defaultPaymentMethod = paymentMethod;
    }
    if (paymentMethod.methodType === 'APPLEPAY') {
      applePayPaymentMethod = paymentMethod;
    }
    return paymentMethod;
  });

  return {
    response: {
      paymentMethods,
      paymentMethodIds,
      defaultPaymentMethod,
      applePayPaymentMethod,
    },
  };
};

export const prepareCheckUserExistsResponse = ({ data, state }) => {
  const { response } = data;

  return {
    response: {
      firstName: response.firstName || state.firstName,
      socialLogin: response.isSocialLogin,
    },
  };
};

export const prepareVerifyPinResponse = ({ state }) => ({
  response: {
    phone: state.pendingPhone,
    pendingPhone: null,
    phoneConfirmed: true,
  },
});

export const prepareProfileResponse = ({ data }) => {
  const { response } = data.response;

  return {
    response: {
      uuid: response.userUuid,
      email: response.email,
      phone: response.phone,
      phoneConfirmed: response.phone !== null,
      firstName: response.firstName,
      lastName: response.lastName,
      dob: response.dob,
      partnerEmail: response.secondaryEmail,
      partnerEmailStatus: response.secondaryEmailStatus,
      userStatus: response.status,
      marketingOptIn: response.marketingOptIn,
    },
  };
};

export const prepareAuthResponse = ({ data }) => {
  const { response } = data;

  return {
    response: {
      token: response.response.token,
    },
  };
};

export const prepareFacebookAuthResponse = ({ data }) => {
  const { response } = data;

  return {
    response: {
      token: response.response.token,
      socialLogin: true,
    },
  };
};

export const prepareAddPaymentMethodResponse = ({ data }) => ({
  response: {
    addPaymentMethodUrl: data.response.redirectUrl,
    /* For backwards compatatibility, default to GET method */
    addPaymentMethodRedirectHttpMethod: data.response.redirectHttpMethod || 'GET',
    addPaymentMethodType: data.response.paymentMethodType,
  },
});

export const prepareSetDefaultPaymentMethodResponse = ({ data }) => ({
  response: {
    defaultPaymentMethod: data,
  },
});

export const prepareForgetPasswordResponse = () => ({
  response: {
    isPasswordModalShown: true,
  },
});

export const prepareUpdateProfileResponse = ({ requestOptions }) => {
  const { firstName, lastName, dob, gender, marketingOptIn } = requestOptions;

  return {
    response: {
      firstName,
      lastName,
      dob,
      gender,
      marketingOptIn,
    },
  };
};

export const prepareUpdatePartnerEmailResponse = ({ data }) => {
  const { secondaryEmail } = data.response.response;

  return {
    response: {
      partnerEmail: secondaryEmail,
      partnerEmailStatus: accountStatusEnum.PENDING,
    },
  };
};

export const prepareRemovePartnerEmailResponse = () => ({
  response: {
    partnerEmail: null,
    partnerEmailStatus: accountStatusEnum.DISABLED,
  },
});

export const prepareReverifyPartnerEmailResponse = () => ({
  response: {
    partnerEmailStatus: accountStatusEnum.PENDING,
  },
});

export const prepareScanHistoryResponse = ({ data }) => ({
  response: {
    scanHistory: {
      ...data.response,
    },
  },
});

export const prepareInitDataResponse = ({ data }) => ({
  response: {
    ...data.response,
    collections: [...data.response.loyalty],
    promos: [...data.response.promo],
    specials: [...data.response.special],
    promo: undefined,
  },
});

export const prepareFetchMassRewardResponse = ({ data }) => ({
  response: {
    massRewards: [...data.response.rewards],
    massRewardClaims: [...data.response.claims],
  },
});

export const prepareClaimMassRewardResponse = ({ data, state }) => ({
  response: {
    claims: [...state.claims.filter((claim) => claim.uuid !== data.response.uuid), data.response],
    selectedMassReward: data.response,
  },
});
